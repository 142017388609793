<template>
  <div class="Login" style="margin-bottom: 50px; width: 100%; text-align:center;">
    <img src="../assets/logo.png" width="100px;" style="margin-top: 50px; margin-bottom: 50px; text-align: center" alt="">
    <h1 style=" text-align: center">VIMS Admin App</h1>
    <h3 style=" text-align: center">We are logging you out...</h3>
    <v-container v-if="this.loader">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-container>
    <v-snackbar
      v-model="snackBar"
      :timeout="snackTime"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackBar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

import axios from 'axios';
// axios.defaults.withCredentials = true;
import {mapGetters, mapMutations} from "vuex";
export default {
  name: 'Login',
  components: {
  },
  data () {
    return {
      loader: false,
      snackBar: false,
      snackText: '',
      snackTime: 3000,
    }
  },
  async mounted(){
    try {
      this.loader = true
      let res = await axios.post(`${this.getEndpoint}/auth/logout`)
      if(res.status == 200){
        this.logout()
        this.$router.push({path: "/login"})  
      }      
    } catch (error) {
      console.log(error)
      this.snackText = "❌ Could not log user out. "
      this.snackBar = true;  
      this.loader = false    
    }
  },
  computed: {
    ...mapGetters(['getEndpoint'])
  },
  methods: {
    ...mapMutations(['logout'])
  }

}
</script>
